import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { SuccessSnackbarComponent } from '../success-snackbar/success-snackbar.component';

@Component({
  selector: 'movies-platform-danger-snackbar',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './danger-snackbar.component.html',
  styleUrls: ['./danger-snackbar.component.scss'],
})
export class DangerSnackbarComponent implements OnInit {
  constructor(
    private readonly ref: MatSnackBarRef<SuccessSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public readonly data: { message: string; title: string }
  ) {}

  ngOnInit(): void {}

  onClose(): void {
    this.ref.dismiss();
  }
}
