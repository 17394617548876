<div class="flex flex-row gap-x-2 items-center">
    <div class="w-1 h-12 bg-yellow-500 rounded-sm shrink-0"></div>
    <mat-icon class="text-yellow-500 shrink-0">warning</mat-icon>
    <div class="ml-2 flex flex-col">
        <span class="mat-subtitle-1 !m-0">{{ data.title }}</span>
        <span class="text-xs opacity-90">{{ data.message }}</span>
    </div>

    <button class="ml-auto" mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>