import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DangerSnackbarComponent } from '../common/snackbar/danger-snackbar/danger-snackbar.component';
import { SnackBarData } from '../common/snackbar/snackbar.interface';
import { SuccessSnackbarComponent } from '../common/snackbar/success-snackbar/success-snackbar.component';
import { WarnSnackbarComponent } from '../common/snackbar/warn-snackbar/warn-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  constructor(private readonly snackbar: MatSnackBar) {}
  error(title: string, message: string) {
    this.snackbar.openFromComponent(DangerSnackbarComponent, {
      data: {
        title,
        message,
      } as SnackBarData,
    });
  }

  success(title: string, message: string) {
    this.snackbar.openFromComponent(SuccessSnackbarComponent, {
      data: { title, message } as SnackBarData,
    });
  }

  warn(title: string, message: string) {
    this.snackbar.openFromComponent(WarnSnackbarComponent, {
      data: { title, message } as SnackBarData,
    });
  }

  entity = {
    success: (entity: string) =>
      this.success(`${entity} Loaded Successfully`, ''),
    error: (entity: string) => this.success(`${entity} Failed To Load`, ''),
  };
  actions = {
    delete: {
      success: (entity: string) =>
        this.success(
          `${entity} Was Deleted`,
          `${entity} was deleted successfully`
        ),
      failed: (entity: string) =>
        this.success(
          `Failed To Delete ${entity}`,
          `${entity} was not deleteded`
        ),
    },
  };
}
