<div class="flex flex-row gap-x-sm items-center">
    <div class="w-1 h-12 bg-red-500 rounded-sm shrink-0"></div>
    <mat-icon class="text-red-500 shrink-0">error</mat-icon>
    <div class="ml-2 flex flex-col">
        <span class="mat-subtitle-1 !m-0">{{ data.title }}</span>
        <p class="text-xs opacity-90 whitespace-pre-line">{{ data.message }}</p>
    </div>

    <button class="ml-auto" mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>